@font-face {
    font-family: 'Chakra Petch';
    src: url('../fonts/ChakraPetch-Regular.eot');
    src: url('../fonts/ChakraPetch-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ChakraPetch-Regular.woff2') format('woff2'),
    url('../fonts/ChakraPetch-Regular.woff') format('woff'),
    url('../fonts/ChakraPetch-Regular.ttf') format('truetype'),
    url('../fonts/ChakraPetch-Regular.svg#ChakraPetch-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Chakra Petch';
    src: url('../fonts/ChakraPetch-Light.eot');
    src: url('../fonts/ChakraPetch-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ChakraPetch-Light.woff2') format('woff2'),
    url('../fonts/ChakraPetch-Light.woff') format('woff'),
    url('../fonts/ChakraPetch-Light.ttf') format('truetype'),
    url('../fonts/ChakraPetch-Light.svg#ChakraPetch-Light') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Chakra Petch';
    src: url('../fonts/ChakraPetch-Bold.eot');
    src: url('../fonts/ChakraPetch-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ChakraPetch-Bold.woff2') format('woff2'),
    url('../fonts/ChakraPetch-Bold.woff') format('woff'),
    url('../fonts/ChakraPetch-Bold.ttf') format('truetype'),
    url('../fonts/ChakraPetch-Bold.svg#ChakraPetch-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Monda';
    src: url('../fonts/Monda-Regular.eot');
    src: url('../fonts/Monda-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Monda-Regular.woff2') format('woff2'),
    url('../fonts/Monda-Regular.woff') format('woff'),
    url('../fonts/Monda-Regular.ttf') format('truetype'),
    url('../fonts/Monda-Regular.svg#Monda-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Monda';
    src: url('../fonts/Monda-Bold.eot');
    src: url('../fonts/Monda-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Monda-Bold.woff2') format('woff2'),
    url('../fonts/Monda-Bold.woff') format('woff'),
    url('../fonts/Monda-Bold.ttf') format('truetype'),
    url('../fonts/Monda-Bold.svg#Monda-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }