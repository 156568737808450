body {
    background-color: #070625;
    font-family: "Monda";
    color: #60DFFF;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    height: 100vh;
    width: 100vh;
}

:focus-visible {
    outline: none;
}

:focus:not(:focus-visible) {
    outline: none;
}

.ff-cp--regular {
    font-family: "Chakra Petch";
    font-weight: normal;
}

.ff-cp--light {
    font-family: "Chakra Petch";
    font-weight: 200;
}

.ff-cp--semibold {
    font-family: "Chakra Petch";
    font-weight: 500;
}

.ff-cp--bold {
    font-family: "Chakra Petch";
    font-weight: bold;
}

.ff-m--regular {
    font-family: "Monda";
    font-weight: normal;
}


.ff-m--bold {
    font-family: "Monda";
    font-weight: bold;
}

.text--webkit {
    text-align: -webkit-center;
}

.grid-rows-10 {
    grid-template-rows: repeat(10, minmax(0, 1fr));
}

.row-span-9 {
    grid-row: span 9 / span 9;
}