.MuiInputBase-input,
.MuiInputBase-input:hover {
    background-color: #073147 !important;
    border: 1.5px solid #07DACB !important;
    font-size: 12px !important;
    color: #07DACB !important;
    border-radius: 10px !important;
    font-weight: bold !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 32px !important;
    padding: 0 10px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -7px !important;
    color: #07DACB !important;
    font-size: 12px !important;
    font-weight: bold !important;
}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.css-8wfwvd-MuiTypography-root-MuiMultiInputDateRangeField-separator {
    display: none;
}