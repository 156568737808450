input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #60DFFF !important;
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 1000px #070625 inset !important
}

input:focus{
    border-color: #60dfff;
}

.input-file:hover div {
    opacity: 100;
}