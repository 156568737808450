::-webkit-scrollbar {
	display: none;
}

.tmc-scroll {
    .rcs-custom-scrollbar {
        width: 45px;
    }
    .rcs-outer-container {
        height: 100%;
        position: relative;
        &:after{
            content: '';
            height: 100%;
            width: 1.5px;
            background: #f50099;
            position: absolute;
            top: 0;
            right: 17px;
        }
    }
    .rcs-inner-container{
        height: 100%;
    }
    .rcs-inner-handle {
        position: relative;
        background-color: inherit;
        background-image: url("../images/icons/icon-scroll-bar.svg");
        background-repeat: no-repeat no-repeat;
        background-size: cover;
        background-position: center;
        top: -15px;
        width: 60px;
        height: 60px;
    }
}

.dropdown-scroll::-webkit-scrollbar {
    display: initial;
    width: 10px;
}

.dropdown-scroll::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 8px;
}

.dropdown-scroll::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.268);
    border-radius: 8px;
}