.react-joyride__tooltip{
    border-radius: 20px !important;
    border: 2px solid #60DFFF;
}

.react-joyride__overlay{
    height: 100vh !important;
    overflow: hidden;
}
.demo{
    &:after{
        content: '';
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: cover;
        width: 459px;
        height: 540px;
        position: absolute;
        transition: ease all 0.3s;
        z-index: 200;
    }
    &.demo-run{
        &:after{
            opacity: 1;
            visibility: visible;
        }
    }
    &.demo-0,
    &.demo-2,
    &.demo-4,
    &.demo-6,
    &.demo-7,
    &.demo-8,
    &.demo-9,
    &.demo-10,
    &.demo-11{
        &:after{
            top: 0;
            right: 0;
            background-image: url('../images/intro/bg-vieja-destino-rtl.png');
        }
    }
    &.demo-1,
    &.demo-5{
        &:after{
            left: 0;
            right: 100px;
            margin: auto;
            top: 0;
            background-image: url('../images/intro/bg-vieja-destino.png');
        }
    }
    &.demo-3{
        &:after{
            top: 0;
            left: 100px;
            right: 0;
            margin: auto;
            background-image: url('../images/intro/bg-vieja-destino-rtl.png');
        }
    }
}