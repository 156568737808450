@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: #60DFFF;
    }

    28% {
        transform: translateY(-7px);
        background-color: #60DFFF;
    }

    44% {
        transform: translateY(0px);
        background-color: #60DFFF;
    }
}

@keyframes OpacityAnimation {
    0% {
        opacity: 0;
    }

    28% {
        opacity: 0.3;
    }

    44% {
        opacity: 0.6;
    }

    70% {
        opacity: 1;
    }
}

@keyframes OpacityChatAnimation {
    0% {
        opacity: 0;
    }

    28% {
        opacity: 0.3;
    }

    44% {
        opacity: 0.6;
    }

    70% {
        opacity: 1;
    }
}